<template>
  <div class="d-flex flex-column">
    <div class="container">
      <div class="row mt-5 justify-content-center">
        <div class="col-xxl-6">
          <span class="fs-4">Elecciones a Centros Internos, Delegados ICUs, FUL, FUAD  2024-2026</span>
        </div>
      </div>
      <div class="row mt-5 justify-content-center">
        <div class="col-xxl-6">
          <div class="card mb-5 mb-xl-8 card-bordered border-gray-300 border-top">

            <div class="card-header mb-5">
              <div class="row pt-5 pb-5 w-100">
                <div class="col-12 mt-3 col-xl-9 col-lg-9 col-xxl-9">
                  <input v-model="formulario.codigo" type="number" class="form-control" placeholder="Código/Registro"/>
                </div>
                <div class="col-12 mt-3 col-xl-3 col-lg-3 col-xxl-3">
                  <button
                      :disabled="!vista.vBotonBuscar"
                      id="botonBuscar"
                      @click="servicioBuscar()"
                      class="btn btn-warning w-100" style="background-color: #fd7e14; color: white">
                    Buscar
                  </button>
                </div>
              </div>
            </div>

            <div class="card-body mb-3">
              <div v-if="!vista.vBotonBuscar" class="row">
                <div class="col-12">
                  <div class="d-flex justify-content-center py-20">
                    <div class="spinner-border text-warning" style="width: 4rem; height: 4rem; border-width: 0.35rem" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="vista.vDato">

                <div v-if="dato != null" class="fs-6">
                  <img src="img/fotos/portada-uficct.jpg" class="img-fluid mb-3" alt="support-ficct" />

                  <div class="row pb-2 w-100">
                    <div class="col-xxl-4 col-xl-4">
                      <span class="fw-bolder">Código/Registro:</span>
                    </div>
                    <div class="col-xxl-8 col-xl-8">
                      <span v-text="dato.persona.codigo"></span>
                    </div>
                  </div>

                  <div class="row pb-2 w-100">
                    <div class="col-xxl-4 col-xl-4">
                      <span class="fw-bolder">Nombre:</span>
                    </div>
                    <div class="col-xxl-8 col-xl-8">
                      <span v-text="dato.persona.nombre" ></span>
                    </div>
                  </div>

                  <div class="row pb-2 w-100">
                    <div class="col-xxl-4 col-xl-4">
                      <span class="fw-bolder">Jurado:</span>
                    </div>
                    <div class="col-xxl-8 col-xl-8">
                      <span v-if="dato.persona.jurado">SI</span>
                      <span v-else>NO</span>
                    </div>
                  </div>

                  <div v-if="dato.persona.jurado" class="row pb-2 w-100">
                    <div class="col-xxl-4 col-xl-4">
                      <span class="fw-bolder">Facultad Jurado:</span>
                    </div>
                    <div class="col-xxl-8 col-xl-8">
                      <span v-text="dato.persona.jurado.facultad"></span>
                    </div>
                  </div>

                  <div v-if="dato.persona.jurado" class="row pb-2 w-100">
                    <div class="col-xxl-4 col-xl-4">
                      <span class="fw-bolder">Mesa Jurado:</span>
                    </div>
                    <div class="col-xxl-8 col-xl-8">
                      <span v-text="dato.persona.jurado.mesa"></span>
                    </div>
                  </div>

                  <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
                    <li v-if="dato.datosCI.length >= 1" class="nav-item" role="presentation">
                      <button class="nav-link active" id="ci-tab" data-bs-toggle="tab" data-bs-target="#ci-tab-pane" type="button" role="tab" aria-controls="ci-tab-pane" aria-selected="true">Centros Internos</button>
                    </li>
                    <li v-if="dato.datosICU.length >= 1" class="nav-item" role="presentation">
                      <button class="nav-link" id="icu-tab" data-bs-toggle="tab" data-bs-target="#icu-tab-pane" type="button" role="tab" aria-controls="icu-tab-pane" aria-selected="false">ICU.</button>
                    </li>
                    <li v-if="dato.datosFUL.length >= 1" class="nav-item" role="presentation">
                      <button class="nav-link" id="ful-tab" data-bs-toggle="tab" data-bs-target="#ful-tab-pane" type="button" role="tab" aria-controls="ful-tab-pane" aria-selected="false">FUL.</button>
                    </li>
                    <li v-if="dato.datosFUAD.length >= 1" class="nav-item" role="presentation">
                      <button class="nav-link" id="fuad-tab" data-bs-toggle="tab" data-bs-target="#fuad-tab-pane" type="button" role="tab" aria-controls="fuad-tab-pane" aria-selected="false">FUAD.</button>
                    </li>
                  </ul>
                  <div class="tab-content" id="myTabContent">
                    <!-- ESTUDIANTE CENTRO INTERNO-->
                    <div class="tab-pane fade show active" id="ci-tab-pane" role="tabpanel" aria-labelledby="ci-tab" tabindex="0">
                      <div v-for="d in dato.datosCI" v-bind:key="d.id">

                        <img v-if="d.carrera == 'INGENIERÍA EN SISTEMAS'" src="img/fotos/portada-sisficct.jpg" class="img-fluid mb-3" alt="u-ficct" />
                        <img v-if="d.carrera == 'INGENIERÍA INFORMÁTICA'" src="img/fotos/portada-infoficct.jpg" class="img-fluid mb-3" alt="u-ficct" />
                        <img v-if="d.carrera == 'INGENIERÍA EN REDES Y TELECOMUNICACIONES'" src="img/fotos/portada-redficct.jpg" class="img-fluid mb-3" alt="u-ficct" />

                        <ul class="list-group">
                          <li class="list-group-item">
                            <div>
                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4">
                                  <span class="fw-bolder">Facultad:</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8">
                                  <span v-text="d.facultad"></span>
                                </div>
                              </div>

                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4">
                                  <span class="fw-bolder">Carrera:</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8">
                                  <span v-if="d.carrera != null" v-text="d.carrera"></span>
                                  <span v-else>NO DISPONIBLE</span>
                                </div>
                              </div>

                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4">
                                  <span class="fw-bolder">Lugar:</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8">
                                  <span v-if="d.lugar != null" v-text="d.lugar"></span>
                                  <span v-else>NO DISPONIBLE</span>
                                </div>
                              </div>

                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4 col-3">
                                  <span class="fw-bolder">Mesa:</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8 col-9">
                                  <span v-if="d.mesa != null" v-text="d.mesa"></span>
                                  <span v-else>NO DISPONIBLE</span>
                                </div>
                              </div>

                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4 col-3">
                                  <span class="fw-bolder">Módulo</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8 col-9">
                                  <span v-if="d.modulo != null" v-text="d.modulo"></span>
                                  <span v-else>NO DISPONIBLE</span>
                                </div>
                              </div>

                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4 col-3">
                                  <span class="fw-bolder">Aula:</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8 col-9">
                                  <span v-if="d.aula != null" v-text="d.aula"></span>
                                  <span v-else>NO DISPONIBLE</span>
                                </div>
                              </div>

                              <div class="row pb-2 w-100">
                                <div class="col-xxl-4 col-xl-4 col-3">
                                  <span class="fw-bolder">Piso:</span>
                                </div>
                                <div class="col-xxl-8 col-xl-8 col-9">
                                  <span v-if="d.piso != null" v-text="d.piso"></span>
                                  <span v-else>NO DISPONIBLE</span>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <!-- ESTUDIANTE ICU-->
                    <div class="tab-pane fade" id="icu-tab-pane" role="tabpanel" aria-labelledby="icu-tab" tabindex="0">
                      <div class="tab-pane fade show active" id="icu-tab-pane" role="tabpanel" aria-labelledby="icu-tab" tabindex="0">
                        <div v-for="d in dato.datosICU" v-bind:key="d.id">

                          <img src="img/fotos/portada-icuficct.jpg" class="img-fluid mb-3" alt="support-ficct" />

                          <ul class="list-group">
                            <li class="list-group-item">
                              <div>
                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Facultad:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-text="d.facultad"></span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Carrera:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-if="d.carrera != null" v-text="d.carrera"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Lugar:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-if="d.lugar != null" v-text="d.lugar"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Mesa:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.mesa != null" v-text="d.mesa"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Módulo</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.modulo != null" v-text="d.modulo"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Aula:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.aula != null" v-text="d.aula"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Piso:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.piso != null" v-text="d.piso"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- ESTUDIANTE FUL-->
                    <div class="tab-pane fade" id="ful-tab-pane" role="tabpanel" aria-labelledby="ful-tab" tabindex="0">
                      <div class="tab-pane fade show active" id="ful-tab-pane" role="tabpanel" aria-labelledby="ful-tab" tabindex="0">
                        <div v-for="d in dato.datosFUL" v-bind:key="d.id">

                          <ul class="list-group">
                            <li class="list-group-item">
                              <div>
                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Facultad:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-text="d.facultad"></span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Carrera:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-if="d.carrera != null" v-text="d.carrera"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Lugar:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-if="d.lugar != null" v-text="d.lugar"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Mesa:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.mesa != null" v-text="d.mesa"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Módulo</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.modulo != null" v-text="d.modulo"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Aula:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.aula != null" v-text="d.aula"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Piso:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.piso != null" v-text="d.piso"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- ESTUDIANTE FUAD-->
                    <div class="tab-pane fade" id="fuad-tab-pane" role="tabpanel" aria-labelledby="fuad-tab" tabindex="0">
                      <div class="tab-pane fade show active" id="fuad-tab-pane" role="tabpanel" aria-labelledby="fuad-tab" tabindex="0">
                        <div v-for="d in dato.datosFUAD" v-bind:key="d.id">
                          <ul class="list-group">
                            <li class="list-group-item">
                              <div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4">
                                    <span class="fw-bolder">Lugar:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8">
                                    <span v-if="d.lugar != null" v-text="d.lugar"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Mesa:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.mesa != null" v-text="d.mesa"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Módulo</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.modulo != null" v-text="d.modulo"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Aula:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.aula != null" v-text="d.aula"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>

                                <div class="row pb-2 w-100">
                                  <div class="col-xxl-4 col-xl-4 col-3">
                                    <span class="fw-bolder">Piso:</span>
                                  </div>
                                  <div class="col-xxl-8 col-xl-8 col-9">
                                    <span v-if="d.piso != null" v-text="d.piso"></span>
                                    <span v-else>NO DISPONIBLE</span>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              <!-- NO HABILITADO PARA VOTAR-->
              <div v-show="vista.vNoHabilitado">
                <img src="img/fotos/portada-uficct.jpg" class="img-fluid mb-3" alt="support-ficct" />

                <div class="row pt-5 pb-0 w-100">
                  <div class="col-xxl-4">
                    <span class="fs-6 fw-bolder">Opción N°1:</span>
                  </div>
                  <div class="col-xxl-8">
                    <span class="fs-6">Usted no esta habilitado para votar, debido a que no esta inscrito en ninguna materia en este momento, no se le aplicará ninguna multa ni bloqueo.</span>
                  </div>
                </div>
                <div class="row pt-5 pb-0 w-100">
                  <div class="col-xxl-4">
                    <span class="fs-6 fw-bolder">Opción N°2:</span>
                  </div>
                  <div class="col-xxl-8">
                    <span class="fs-6">El código/registro introducido no existe.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-naranja"></div>
        </div>
      </div>
    </div>
    </div>
    <div class="row justify-content-center">
      <img class="rounded" width="150" src="logos/uficct.png" alt="logo-uficct" style="width: 150px">
    </div>
  </div>
</template>

<script>

import Servicio from "@/utilidades/Servicio";
import Ruta from "@/utilidades/Ruta";

export default {
  name: 'HomeView',

  data() {
    return {
      dato: {
        persona: {
          id: '',
          codigo: '',
          nombre: '',
          carnetIdentidad: '',
          codFacultad: '',
          facultad: '',
          codCarrera: '',
          carrera: '',
          lugar: '',
          mesa: '',
          modulo: '',
          aula: '',
          piso: '',
          jurado: ''
        },
        datosCI: [],
        datosICU: [],
        datosFUL: [],
        datosFUAD: [],
      },
      datos: [],
      formulario: {
        codigo: ''
      },
      vista: {
        vBotonBuscar: true,
        vDato: false,
        vNoHabilitado: false,
        vFoto: false,
      }
    }
  },
  methods: {
    servicioBuscar() {
      const botonGuardar = document.querySelector("#botonBuscar");
      this.vista.vDato = false;
      this.vista.vBotonBuscar = false;
      this.vista.vNoHabilitado = false;
      this.vista.vFoto = false;
      botonGuardar.setAttribute("data-kt-indicator", "on");
      Servicio.get(Ruta.rutaBuscarPersona(this.formulario.codigo)).then((data)=>{
        this.vista.vBotonBuscar = true;
        botonGuardar.removeAttribute("data-kt-indicator");

        if(data.data != null) {
          this.dato = data.data;
          this.vista.vDato = true;
        } else {
          this.vista.vNoHabilitado = true;
        }
        this.vista.vFoto = true;
      }).catch((error)=>{
        this.vista.vDato = true;
        this.vista.vBotonBuscar = true;
        console.log(error);
        botonGuardar.removeAttribute("data-kt-indicator");
      });
    }
  }
}
</script>
