<template>
  <div id="kt_footer" class="footer py-4 d-flex flex-column">
    <div class="container-fluid d-flex flex-column flex-md-row justify-content-center">
      <div class="text-dark order-2 order-md-1 text-center">
        <span class="text-muted fw-bold me-1">2024© U.FICCT</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ViewFooter'
}
</script>
