<template>
  <div>
    <div class="d-flex flex-column">
      <div class="page d-flex flex-row">
        <div class="d-flex flex-fill flex-column">
          <NavBar></NavBar>

          <div id="kt_content" class="d-flex flex-column">
            <router-view/>
          </div>

          <Footer></Footer>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="css">
.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
<script>
import NavBar from '@/components/plantilla/NavBar.vue';
import Footer from '@/components/plantilla/Footer.vue';

export default {
  name: 'ViewHome',
  components: {
    NavBar,
    Footer
  }
}
</script>